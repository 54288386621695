<template>
    <div class="dashboard hc-55px py-4">
        <div class="row justify-content-center mx-0">
            <div class="col-12 col-lg-10">
                <div>
                    <p class="text-blue f-26 f-bold ucfirst">{{ datos.administrador }},</p>
                    <div class="d-middle-bt mb-3 flex-wrap">
                        <p class="text-general f-18">estas son las generalidades de tu copropiedad</p>
                        <p class="text-blue f-20 f-500 ucfirst">{{ currentTime }}</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="br-10 bs-036-29 border text-center p-4 bg-white">
                                <img :src="datos.conjunto.logo" alt="" class="wf-180px hf-120px br-10 border mx-auto obj-cover" />
                                <p class="f-15 mt-3 text-black f-500 lh-25 ucfirst">{{ datos.conjunto.nombre }}</p>
                                <p class="my-2 text-general f-400">{{ datos.conjunto.ciudad }}</p>
                                <p class="my-2 text-general f-400 ucfirst">{{ datos.conjunto.direccion }}</p>
                            </div>
                            <mini-card titulo="Viviendas" :cantidad="datos.viviendas" icono="icon-home" class="my-4 p-4 bg-white" />
                            <mini-card titulo="Vigilantes" :cantidad="datos.usuarios.vigilantes" icono="icon-vigilantes" class="my-4 p-4 bg-white" />
                        </div>
                        <div class="col-lg-8">
                            <div class="row">
                                <div class="col-lg-6">
                                    <mini-card titulo="Residentes" :cantidad="datos.usuarios.residentes" icono="icon-resident" f-icono="f-30" class="pl-2 pr-4 h-80px bg-white" />
                                </div>
                                <div class="col-lg-6">
                                    <mini-card titulo="Propietarios" :cantidad="datos.usuarios.propietarios" icono="icon-account-lock-open" f-icono="f-30" class="pl-2 pr-4 h-80px bg-white" />
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <div class="bg-general p-3 br-15">
                                        <div class="d-middle-bt mb-3 text-white mb-2">
                                            <p class="f-20 f-500">Solicitudes pendientes</p>
                                            <p class="f-24 f-600">{{ datos.catSolicitudes }}</p>
                                        </div>
                                        <div v-if="accionHome">
                                            <mini-card titulo="Solicitudes de Residentes" :cantidad="datos.solicitudes.residentes" img="/img/generales/Solicitudes.png" class="bg-white h-80px px-3 mb-3 cr-pointer" action @goTo="goTo('viviendas.solicitudes')" />
                                            <mini-card titulo="Reservas pendientes" :cantidad="datos.solicitudes.reservas" img="/img/generales/Reservas.png" class="bg-white h-80px px-3 mb-3 cr-pointer" action @goTo="goTo('zonas.reservas')" />
                                            <mini-card titulo="Solicitudes de anuncios" :cantidad="datos.solicitudes.anuncios" img="/img/generales/Anuncios.png" class="bg-white h-80px px-3 mb-3 cr-pointer" action @goTo="goTo('anuncios.solicitudes')" />
                                            <mini-card titulo="Peticiones, quejas y reclamos" :cantidad="datos.solicitudes.pqr" img="/img/generales/PQR.png" class="bg-white h-80px px-3 cr-pointer" action @goTo="goTo('pqr.solicitudes')" />
                                        </div>
                                        <div v-else>
                                            <mini-card titulo="Solicitudes de Residentes" :cantidad="datos.solicitudes.residentes" img="/img/generales/Solicitudes.png" class="bg-white h-80px px-3 mb-3 cr-pointer" />
                                            <mini-card titulo="Reservas pendientes" :cantidad="datos.solicitudes.reservas" img="/img/generales/Reservas.png" class="bg-white h-80px px-3 mb-3 cr-pointer" />
                                            <mini-card titulo="Solicitudes de anuncios" :cantidad="datos.solicitudes.anuncios" img="/img/generales/Anuncios.png" class="bg-white h-80px px-3 mb-3 cr-pointer" />
                                            <mini-card titulo="Peticiones, quejas y reclamos" :cantidad="datos.solicitudes.pqr" img="/img/generales/PQR.png" class="bg-white h-80px px-3 cr-pointer" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import miniCard from './components/miniCard.vue'
import Dashboard from '~/services/dashboard'
import moment from 'moment'

export default {
    name: 'Dashboard',
    components: {
        miniCard
    },
    data(){
        return {
            data: {
                conjunto: {
                    img: '/img/pruebas/piscina0.jpg',
                    nombre: 'EL OLYMPO CONCOMINIO & RESORT',
                    lugar: 'Floridablanca',
                    direccion: 'Clle 25 # 73 - 108A',
                    cantViviendas: 500,
                    cantVigilantes: 4,
                    cantResidentes: 350,
                    cantPropietarios: 20,
                },
            },
            datos: {
                administrador: '',
                catSolicitudes: 0,
                viviendas: 0,
                conjunto: {
                    id: null,
                    logo: '',
                    ciudad: '',
                    direccion: '',
                    nombre: ''
                },
                solicitudes:{
                    anuncios: 0,
                    pqr: 0,
                    reservas:0,
                    residentes: 0
                },
                usuarios: {
                    propietarios: 0,
                    residentes: 0,
                    vigilantes: 0
                },
            },
            accionHome: true
        }
    },
    async created(){
            if(this.$usuario && (this.$usuario.tipo == 4 || this.$usuario.tipo == 6)){ //Vigilante
                this.accionHome = false;
            }
    },
    computed: {
        currentTime(){
            return moment().format('dddd, D MMMM YYYY').toString()
        }
    },
    mounted(){
        this.listarDatosDashboard();
    },
    methods: {
        goTo(name){
            this.$router.push({ name })
        },
        async listarDatosDashboard(){
            try {
                const { data } = await Dashboard.getDashboard();
                if(data.success) this.datos = data.data;
            } catch (error){
                return this.errorCatch(error)
            }
        },
    },

}
</script>
<style lang="scss" scoped>
.dashboard{
    width: calc(100vw - 63px);
    background-color: #F6F9FB;
    .card-dash{
        box-shadow: 0px 3px 6px #00000029;
    }
}
</style>
